<template>
  <div>
    <div class="all_context">
      <div class="all_context_flex">
        <div class="storeCenter_item_top_left">
          <div class="banner_box">
            <div class="activity_state two" v-if="headData.clueState === '正常'">
              <span>{{ headData.clueState }}</span>
            </div>
            <div class="activity_state three" v-else>
              <span>{{ headData.clueState }}</span>
            </div>
            <img v-if="headData.clueImage" :src="headData.clueImage" alt="" />
          </div>
          <div class="storeCenter_item_top_left_flex">
            <div style="display: flex; align-items: center">
              <div class="clue_name">
                {{ headData.clueName }}
              </div>
              <div class="type_culb" v-if="headData.clueType">
                {{ headData.clueType }}
              </div>
            </div>
            <div class="storeCenter_item_top_left_flex_tag">
              <span style="display: flex" v-if="headData.clueTableList">
                <span v-for="(o, index) in headData.clueTableList" :key="index">
                  <span v-if="index < 3" class="office">{{ o }}</span>
                </span>
              </span>

              <el-popover placement="right" width="430" trigger="hover">
                <div
                  class="hidden_label_box"
                  style="
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-content: center;
                    flex-wrap: wrap;
                  "
                >
                  <span
                    class="office"
                    style="
                      border: 1px solid #559eff;
                      color: #4e93fb;
                      box-sizing: border-box;
                      padding: 1px 6px;
                      display: block;
                      font-size: 14px;
                      font-weight: 400;
                      border-radius: 4px;
                      margin-right: 10px;
                      margin-bottom: 10px;
                    "
                    v-for="(o, index) in headData.clueTableList"
                    :key="index"
                    >{{ o }}</span
                  >
                </div>

                <span class="office" slot="reference" v-show="headData.clueTableList.length > 3"
                  >{{ headData.clueTableList.length }}+</span
                >
              </el-popover>
            </div>
            <div style="display: flex" class="row">
              <div class="corp_name">{{ headData.companyFullName }}</div>
              <div style="padding: 0 10px; display: flex" class="clue_stage">
                <img
                  style="height: 16px; width: 16px"
                  src="../../../assets/image/Frame (1).png"
                  v-if="headData.clueStage == '采购前了解'"
                />
                <img
                  style="height: 16px; width: 16px"
                  src="../../../assets/image/Frame (2).png"
                  v-else
                />
                <span style="margin-left: 5px">{{ headData.clueStage }}</span>
              </div>
              <div style="padding: 0 10px">
                {{ headData.customerContactPerson }}
                <span v-if="headData.associatePhone">({{ headData.associatePhone }})</span>
              </div>
            </div>
            <div class="bottom_row">
              <span style="margin-right: 20px">
                所在地区：{{ headData.province }}-{{ headData.city }}
              </span>
              <span style="margin-right: 20px">开始日期：{{ headData.clueStartTime }}</span>
              <span style="margin-right: 20px"
                >预计完成日期：{{ headData.estimatedCompletionTime }}</span
              >
              <!-- <span style="margin-right: 20px">项目完成日期：{{ headData.clueStartTime }}</span> -->
              <span>执行人：{{ headData.userName }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="context">
        <div class="state">
          <div class="left">
            <el-button type="primary" style="margin-right: 15px" @click="addPeople"
              >+新增线索日志</el-button
            >
            <!-- <el-button style="margin-right: 15px" @click="updateOffShelf">批量下架</el-button> -->
          </div>
          <div class="right">
            <div class="seach_box">
              <el-input
                placeholder="请输入线索阶段或对接人"
                v-model="query.keyWord"
                class="input-with-select"
                clearable
                @change="changeSearchangeSearch"
              >
                <el-button class="seach" slot="append" @click="changeSearchangeSearch"
                  >搜索</el-button
                >
              </el-input>
            </div>
          </div>
        </div>
        <!-- @sort-change="changeSort" -->
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中..."
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column label="序号" align="center" width="80px" type="index">
            </el-table-column>
            <el-table-column label="线索阶段" align="center">
              <template slot-scope="scope">{{ scope.row.clueStage }}</template>
            </el-table-column>
            <el-table-column label="线索阶段详述" align="center" :show-overflow-tooltip="true">
              <template slot-scope="scope">{{ scope.row.clueStageDescribe }}</template>
            </el-table-column>
            <el-table-column label="对接人" align="center">
              <template slot-scope="scope">{{ scope.row.customerContactPerson }}</template>
            </el-table-column>
            <el-table-column label="职位" align="center">
              <template slot-scope="scope">{{ scope.row.associatePosition }}</template>
            </el-table-column>
            <el-table-column label="手机号" align="center">
              <template slot-scope="scope">{{ scope.row.associatePhone }}</template>
            </el-table-column>
            <el-table-column label="QQ号" align="center">
              <template slot-scope="scope">{{ scope.row.qq }}</template>
            </el-table-column>
            <el-table-column label="微信号码" align="center">
              <template slot-scope="scope">{{ scope.row.weChatNumber }}</template>
            </el-table-column>
            <!-- <el-table-column label="预计完成日期" align="center">
              <template slot-scope="scope">{{ scope.row.estimatedCompletionTime }}</template>
            </el-table-column>
            <el-table-column label="项目完成日期" align="center">
              <template slot-scope="scope">{{ scope.row.clueEndTime }}</template>
            </el-table-column> -->
            <!-- <el-table-column label="执行人" align="center">
              <template slot-scope="scope">{{ scope.row.createName }}</template>
            </el-table-column> -->
            <el-table-column label="跟进状态" align="center">
              <template slot-scope="scope">{{ scope.row.clueState }}</template>
            </el-table-column>
            <el-table-column label="新增时间" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.createTime | date }}</div>
                <div>{{ scope.row.createTime | time }}</div>
              </template>
            </el-table-column>
            <el-table-column label="跟进时间" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.followUpTime | date }}</div>
                <div>{{ scope.row.followUpTime | time }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template v-slot="{ row }">
                <div class="btn">
                  <el-button
                    type="text"
                    v-if="row.notFreeze"
                    @click="freezeClick(row)"
                    style="color: #f53939"
                    >冻结</el-button
                  >
                  <el-button type="text" @click="freezeClick(row)" v-else>解冻</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="Pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="query.pageNum"
              :page-sizes="[10, 30, 50]"
              :page-size="query.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增线索日志 -->
    <el-dialog :before-close="enterpriseClose" :visible.sync="enterpriseDia" width="50%" center>
      <span slot="title" class="custom-dialog-title">
        <div class="left-text">新增线索日志</div>
      </span>

      <div class="enterprise_dia">
        <el-form
          :model="enterpriseQueryInfo"
          :rules="enterprRules"
          label-width="120px"
          ref="enterpriseRef"
        >
          <el-form-item prop="clueStage" label="线索状态">
            <el-select
              @change="changeStage"
              v-model="enterpriseQueryInfo.clueStage"
              placeholder="选择情况"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.codeName"
                :value="item.codeName"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="clueState" label="跟进状态">
            <el-select v-model="enterpriseQueryInfo.clueState" placeholder="正常">
              <el-option label="正常" value="正常"> </el-option>
              <el-option label="冻结" value="冻结"> </el-option>
              <el-option label="异常" value="异常"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="logContactPerson" label="对接人">
            <el-input
              v-model="enterpriseQueryInfo.logContactPerson"
              placeholder="输入对接人姓名"
              style="width: 202px"
            ></el-input>
          </el-form-item>
          <el-form-item label="线索阶段详述" prop="clueStageDescribe">
            <div class="txtree">
              <el-input
                v-model="enterpriseQueryInfo.clueStageDescribe"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 4 }"
                placeholder="请输入情况"
              />
            </div>
          </el-form-item>
          <el-form-item prop="followUpTime" label="跟进时间">
            <el-radio-group v-model="enterpriseQueryInfo.followUpTime">
              <el-radio label="一天内" value="一天内">一天内</el-radio>
              <el-radio label="三天内" value="三天内">三天内</el-radio>
              <el-radio label="五天内" value="五天内">五天内</el-radio>
              <el-radio
                label="自定义"
                value="自定义"
                class="el-icon-date"
                v-if="enterpriseQueryInfo.followUpTime != '自定义'"
                >自定义</el-radio
              >
            </el-radio-group>
            <el-date-picker
              v-if="enterpriseQueryInfo.followUpTime === '自定义'"
              v-model="enterpriseQueryInfo.customFollowUpTime"
              style="margin-left: 20px"
              size="mini"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="enterpriseClose">取 消</el-button>
        <el-button type="primary" @click="editSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  listTopById,
  recommendById,
  getClueSpecialistResultClueLog,
  insertClueLog,
  updateClueLogFreeze
} from '../../../api/clue.js'
import { getCode } from '@/api/demand.js'
const defaultEnterpriseQueryInfo = Object.freeze({
  clueEndTime: '',
  clueId: '',
  clueStage: '',
  clueStageDescribe: '',
  clueState: '正常',
  createName: '',
  createTime: '',
  logContactPerson: '', // 对接人
  followUpTime: '', // 更进时间
  customFollowUpTime: '', // 自定义时间
  id: 0
})
export default {
  name: 'clueLog',
  computed: {
    activeId() {
      return this.$route.query.id
    }
  },
  data() {
    //这里存放数据
    return {
      enterprRules: {
        clueStage: [{ required: true, message: '请选择线索状态', trigger: 'change' }],
        clueStageDescribe: [{ required: true, message: '请输入线索阶段详述', trigger: 'blur' }],
        followUpTime: [{ required: true, message: '请选择跟进时间', trigger: 'change' }],
        clueState: [{ required: true, message: '请选择跟进状态', trigger: 'change' }],
        logContactPerson: [{ required: true, message: '请输入对接人', trigger: 'blur' }]
      }, //验证规则
      options: [],
      enterpriseQueryInfo: { ...defaultEnterpriseQueryInfo },
      enterpriseDia: false,
      headData: {
        clueTableList: []
      }, ///头部信息
      // 表格数据
      tableData: [],
      loading: false,
      // 分页总条数
      total: 0,
      ids: [],
      // 表格发送数据
      query: {
        id: '',
        orderByParam: 'DESC',
        pageNum: 1,
        pageSize: 10,
        keyWord: ''
      },
      enterpriseIntroShow: false
    }
  },

  created() {
    this.getHeadData()
    this.search()
    this.getOptions()
  },
  //方法集合
  methods: {
    // 冻结解冻
    freezeClick(row) {
      const query = {
        id: row.id,
        notFreeze: !row.notFreeze
      }
      const that = this
      this.$alert(`是否确认${row.notFreeze ? '冻结' : '解冻'}该日志？`)
        .then(function () {
          return updateClueLogFreeze(query)
        })
        .then(() => {
          this.search()
          this.$message.success(`${row.notFreeze ? '冻结' : '解冻'}成功`)
        })
        .catch(() => {})
    },
    // 选择线索阶段
    changeStage(val) {
      if (
        val === '采购前了解' ||
        val === '服务商匹配' ||
        val === '需求确认待对接' ||
        val === '服务商对接' ||
        val === '订单流程'
      ) {
        this.enterpriseQueryInfo.clueState = '正常'
      } else if (val === '项目冻结' || val === '项目终止') {
        this.enterpriseQueryInfo.clueState = '冻结'
      } else if (val === '订单执行') {
        this.enterpriseQueryInfo.clueState = '完成'
      }
    },
    // 获取线索阶段
    async getOptions() {
      const { data: res } = await getCode({ codeType: '020' })
      if (res.resultCode == 200) {
        this.options = res.data
      }
    },
    // 新增日志确定
    editSubmit() {
      this.enterpriseQueryInfo.clueId = this.activeId
      this.$refs['enterpriseRef'].validate(async (valid) => {
        if (valid) {
          if (this.enterpriseQueryInfo.customFollowUpTime) {
            this.enterpriseQueryInfo.followUpTime = this.enterpriseQueryInfo.customFollowUpTime
          }
          const { data: res } = await insertClueLog(this.enterpriseQueryInfo)
          if (res.resultCode === 200) {
            this.enterpriseClose()
            this.$message.success('新增成功')
            this.search()
          } else {
            this.$message.warning('新增失败，请稍候重试')
          }
        } else {
          this.$message.warning('请填写表单信息')
        }
      })
    },
    // 关闭服务商企业弹窗
    enterpriseClose() {
      this.enterpriseDia = false
      this.$refs.enterpriseRef.resetFields()
      this.$refs.enterpriseRef.clearValidate()
      this.enterpriseQueryInfo = { ...defaultEnterpriseQueryInfo }
    },
    //为行设置独有key
    selRowKey(row) {
      return row.id
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.search()
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      this.search()
    },

    // 头部数据
    async getHeadData() {
      const { data: res } = await listTopById({
        id: this.activeId
      })
      if (res.resultCode == 200) {
        this.headData = res.data
        if (!this.headData.clueTableList) {
          this.headData.clueTableList = []
        }
      }
    },

    // 新增服务商弹窗
    addPeople() {
      this.enterpriseDia = true
    },

    // 弹窗表格多选
    handleSelectionChangeDia(selection) {
      this.selectionList = selection
      this.idsList = selection.map((item) => item.id)
    },

    // table表格按钮的选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
    },
    async search() {
      this.query.id = this.activeId
      this.loading = true
      const { data: res } = await getClueSpecialistResultClueLog(this.query)
      if (res.resultCode == 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    //单个上下架
    isShelf(row) {
      const query = {
        ids: [row.id],
        smartId: this.activeId
      }
      const that = this
      this.$alert(`是否确认下架该企业？`)
        .then(function () {
          return recommendById(query)
        })
        .then(() => {
          this.search()
          this.$message.success(`下架成功`)
        })
        .catch(() => {})
    },
    //批量下架
    updateOffShelf() {
      if (this.ids.length > 0) {
        const query = {
          ids: this.ids,
          smartId: this.activeId
        }
        const that = this
        this.$alert('是否确认批量下架该企业？')
          .then(function () {
            return recommendById(query)
          })
          .then(() => {
            this.search()
            this.ids = []
            this.$refs.multipleTable.clearSelection()
            this.$message.success('下架成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择企业')
      }
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      // 2->创建日期升序;3->创建日期降序;4->登陆次数升序;5->登陆次数降序;6->最近登陆时间升序;7->最近登陆时间降序
      console.log(val)
      if (val.prop == 'participationTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时
          this.query.orders = '2'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.query.orders = '3'
          this.search()
        } else {
          //updateAt列不排序时
          this.query.orders = '1'
          this.search()
        }
      } else if (val.prop == 'checkTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.query.orders = '4'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.query.orders = '5'
          this.search()
        } else {
          //updateAt列不排序时
          this.query.orders = '1'
          this.search()
        }
      }
    },
    // 搜索
    changeSearchangeSearch() {
      this.query.pageNum = 1
      this.search()
    }
  }
}
</script>
<style scoped lang="less">
.enterprise_dia {
  width: 100%;
  overflow: hidden;

  .txtree {
    width: 100%;
    ::v-deep .el-textarea {
      width: 400px;
    }
    ::v-deep.el-textarea__inner {
      display: block;
      resize: vertical;
      padding: 5px 15px;
      line-height: 1.5;
      box-sizing: border-box;
      width: 400px;
      font-size: inherit;
      color: #606266;
      background-color: #fff;
      background-image: none;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  .enterprise_dia_flex {
    display: flex;
    flex-direction: row;
    align-items: center;

    ::v-deep .el-input {
      width: 230px;
    }

    span {
      display: block;
      width: 40px;
      margin-left: 12px;
    }
  }
}
/deep/ a {
  color: #4e93fb;
  text-decoration: underline;
}
.div_dia {
  width: 100%;
  overflow: hidden;

  .dia_table {
    margin: 20px 0;
    width: 100%;
  }

  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
}
.all_context {
  padding: 16px 0px 0 0px;
  .span_tag {
    font-size: 14px;
    line-height: 20px;
    color: #4e93fb;
    margin-right: 10px;
    cursor: pointer;
  }

  .all_context_flex {
    width: 100%;
    display: flex;
    background-color: #ffffff;
    padding-right: 50px;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 20px;
    .right {
      position: absolute;
      right: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .total_num {
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: 550;
        color: #ff7d18;
      }
    }
  }
  .storeCenter_item_top_left {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 120px;

    .banner_box {
      position: relative;
      width: 175.55px;
      height: 115.73px;
      padding-right: 10px;
      img {
        width: 170.55px;
        height: 117.73px;
        border-radius: 4px;
        margin-right: 18px;
      }

      .activity_state {
        position: absolute;
        width: 93px;
        height: 34px;
        background: #ff7d18;
        color: #fff;
        text-align: center;
        line-height: 34px;
        border-radius: 10px 10px 10px 0px;
        top: 0px;
        left: 0px;
        transform: translate(-10px, -10px);
      }
      .one {
        background: #ff7d18;
      }
      .two {
        background: #599afb;
      }
      .three {
        background: #999999;
      }
      .four {
        background: #999999;
      }
    }

    .storeCenter_item_top_left_flex {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .clue_num {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6e6e6e;
        font-size: 14px;
        margin-right: 10px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
      .clue_name {
        font-size: 18px;
        font-weight: bolder;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        min-width: 40px;
        max-width: 300px;
        margin-right: 10px;
        -webkit-box-orient: vertical;
      }

      .mark_box {
        display: flex;
        img {
          width: 14px;
          // height: 14px;
          margin-right: 6px;
          color: #6e6e6e;
        }
        & > div {
          margin-left: 10px;
        }
      }
      .img_commer {
        display: flex;
        border: 1px solid #146aff;
        border-radius: 39px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #146aff;
        }
        .color_img {
          background: #146aff;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }
      .img_commers {
        display: flex;
        border: 1px solid #ff9c00;
        border-radius: 39px;
        margin: 0px 10px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #ff9c00;
        }
        .color_img {
          background: #ff9c00;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }

      h4 {
        color: #1f2d3d;
        font-size: 26px;
        font-weight: 500;
        margin: 0;
      }

      .storeCenter_item_top_left_flex_tag {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: center;

        margin-bottom: 1px;

        span {
          box-sizing: border-box;
          display: block;
          font-size: 14px;
          font-weight: 400;
          border-radius: 4px;
          cursor: pointer;
        }

        .office {
          border: 1px solid #559eff;
          color: #4e93fb;
          padding: 1px 6px;
          margin-right: 10px;
          cursor: pointer;
        }

        .support {
          border: 1px solid #fd523f;
          color: #fd523f;
          margin-left: 12px;
          cursor: pointer;
        }
      }

      .address {
        font-size: 16px;
      }
    }
  }
  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .context {
    background-color: #ffffff;
    padding: 0 21px 0 30px;
    border-radius: 8px;
    .pass {
      color: #4e93fb;
      cursor: pointer;
    }
    .un_pass {
      color: #fd6161;
      cursor: pointer;
    }
    .state {
      width: 100%;
      overflow: hidden;
      height: 72px;
      border-bottom: 1px dashed #f3f3f3;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        .item {
          color: #333333;
          font-size: 14px;
          cursor: pointer;
          padding: 15px 20px;
        }
      }
      .right {
        display: flex;
        padding-right: 25px;
        f .icon {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .tex {
          margin-left: 7px;
          color: #595959;
        }
        .time {
          color: #333333;
        }
      }
      /deep/.el-radio {
        margin-right: 0;
      }
    }
    .time_search {
      height: 80px;
      display: flex;
      align-items: center;
      padding-right: 10px;
      justify-content: space-between;
      position: relative;
      .time {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        position: absolute;
        left: 0;

        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        // .item {
        //   color: #333333;
        //   font-size: 14px;
        //   cursor: pointer;
        //   padding: 15px 20px;
        // }
      }
      .search {
        display: flex;
        position: absolute;
        right: 0;
        .search_input {
          width: 430px;
        }
        .search_div {
          width: 74px;
          height: 40px;
          background-color: #448aff;
          color: #fff;
          border-radius: 2px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          transform: translateX(-2px);
        }
      }
    }

    .table {
      margin-top: 20px;
      width: 100%;
      overflow: hidden;
      .imgbox {
        display: flex;
        justify-content: center;

        .img {
          width: 34px;
          height: 34px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .correlationBox {
        display: flex;
        align-items: center;
        justify-content: center;
        .correlation {
          background-color: #fd6161;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          height: 24px;
          color: #fff;
          font-size: 12px;
        }
      }

      .enterpriseIntro {
        // width: 100%;
        // height: 100%;
        width: 376px;
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
        // 控制行数
        -webkit-line-clamp: 2; //超出两行隐藏
        -webkit-box-orient: vertical; // 从上到下垂直排列子元素
        color: #333333;
        position: relative;
      }
      .enterpriseIntroShow {
        position: absolute;
        // right: 0%;
        // bottom: -23%;
        border-radius: 8px;
        // transform: translateX(50%);
        width: 376px;
        padding: 10px 7px 8px 13px;
        background-color: #fff;
        z-index: 999;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }

      /deep/.el-table__body-wrapper {
        overflow: visible;
      }
      /deep/.el-table {
        overflow: visible;
      }
    }
    .Pagination {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 50px 0 50px 0;
    }
  }
}
.add_select {
  .remove_pool {
    box-sizing: border-box;
    width: 348px;
    height: 150px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0px 0px 2px #d4d4d4;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 42px 0 20px 0;
    align-items: center;
    .el-icon-close {
      position: absolute;
      right: 11px;
      top: 11px;
      color: #b6b6b4;
      font-size: 18px;
      cursor: pointer;
    }
    .text {
      margin-bottom: 23px;
    }
    /deep/.el-button {
      width: 112px;
      height: 40px;
    }
  }
  /deep/.el-dialog {
    margin-top: 40vh !important;
  }
}

.type_culb {
  box-sizing: border-box;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #4e93fb;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.correlation {
  /deep/.el-dialog {
    border-radius: 4px;
    position: relative;
  }
  /deep/.el-dialog__body {
    padding: 28px 20px 16px 20px;
  }
  .correlationBox {
    .head {
      position: absolute;
      left: 0;
      top: 12px;
      // transform: translate(-20px, -50px);
      .title {
        border-left: 4px solid #448aff;
        padding-left: 15px;
        font-size: 16px;
        color: #333333;
        font-weight: 500;
      }
    }
    .context {
      .input {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .label {
          position: relative;
          font-size: 14px;
          color: #333333;
          span {
            position: absolute;
            right: -9px;
            top: -2px;
            color: #f05252;
          }
        }
        /deep/.el-input {
          width: 322px;
        }
      }
      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        .text {
          width: 64px;
          height: 36px;
          cursor: pointer;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          background-color: #448aff;
          margin-top: 31px;
        }
      }
    }
  }
}
::v-deep .el-icon-date .el-radio__input {
  display: none;
}
.custom-dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 550;
}
/* 控制最左边的文本样式 */
.left-text {
  padding-left: 10px;
  border-left: 3px solid #448aff;
}
.corp_name {
  font-size: 14px;
  line-height: 20px;
  padding-right: 10px;
  border-right: #e8e8e8 1px solid;
}
.clue_img {
    flex-shrink: 0;
    position: relative;
    width: 160px;
    height: 104px;
    margin-right: 10px;
    border-radius: 1px;
    img {
      width: 160px;
      height: 104px;
      border-radius: 8px;
    }
  }
</style>
